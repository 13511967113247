import { notification } from 'antd';
import { NotificationIcon } from '@/utils';
import apirequest from '@/api';
import { createModel } from './handler/handleModel';

/**
 *  response 的参数定义
 * @param list 列表
 * @param total 返回的总条数
 */
interface Response {
  list: {
    [props: string]: number | string;
  }[];
  defer_count?: number;
  pagination: {
    pageSize: number;
    current: number;
    total: number;
  };
}

const curd = {
  namespace: 'curd',
  state: {
    butLoading: false,
    response: {
      list: [],
      defer_count: 0,
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
      },
    },
  },
  effects: {
    /**
     *
     * @param form 搜索组件的form实例
     * @param value 搜索的参数
     * @param services 调用的参数接口
     * @param pagination 分页携带参数
     * @param rest 其他参数
     */
    *use_query({ form, value, services, query, ...rest }, { call, put, select }) {
      const {
        response: {
          pagination: { pageSize = 10, current = 1 },
        },
      } = yield select((state: any) => state.curd);

      // 接口参数分析
      const [server, method] = services.split('.');
      let data = {};
      const Page = {
        pageSize,
        current,
      };
      data = {
        ...form.getFieldsValue(),
        ...data,
        ...Page,
      };
      // 判断有没有${value}参数
      if (value && Object.keys(value).length > 0) {
        data = {
          ...data,
          ...value,
        };
      }
      if (query && Object.keys(query).length > 0) {
        data = {
          ...data,
          ...query,
        };
      }
      // 接口调用
      const res = yield call(apirequest[server][method], data);
      if (res && res.list) {
        // 对每条数据添加显示隐藏图标
        // 如果返回成功改变参数
        const list_data = {
          ...res,
        };
        yield put({
          type: 'savelist',
          response: list_data,
        });
      } else if (res.code === 401) {
        // 返回失败报错
        notification.open({
          message: '错误',
          description: '登录超时请重新登录',
          icon: NotificationIcon('error'),
        });
      } else if (res.code > 300) {
        // 返回失败报错
        notification.open({
          message: '错误',
          description: res.message,
          icon: NotificationIcon('error'),
        });
      } else {
        notification.open({
          message: '意外错误',
          description: '请联系系统管理员',
          icon: NotificationIcon('warning'),
        });
      }
    },
    *handle_pagination({ pagination, form, value, services, query }, { put }) {
      yield put({
        type: 'save_pagination',
        pagination,
      });
      yield put({
        type: 'use_query',
        form,
        value,
        services,
        query,
      });
    },
    *changeHide({ selectIndex, status }, { put, select }) {
      const { response } = yield select((state: any) => state.curd);
      const revalue = JSON.parse(JSON.stringify(response.items));
      const data = revalue.map((item: any, index: number) => {
        if (selectIndex === index) {
          if (status === 'leftIcon') {
            item.leftIcon = !item.leftIcon;
          } else {
            item.hide = !item.hide;
          }
        }
        return item;
      });
      yield put({
        type: 'saveItems',
        payload: data,
      });
    },
    *changeLoading({ payload }, { put }) {
      yield put({
        type: 'saveLoading',
        payload,
      });
    },
  },
  reducers: {
    savelist(state, action: any) {
      return {
        ...state,
        response: {
          ...state.response,
          ...action.response,
        },
      };
    },
    save_pagination(state, action: any) {
      return {
        ...state,
        response: {
          ...state.response,
          // total: 0,
          pagination: action.pagination,
        },
      };
    },
    saveItems(state, action: any) {
      return {
        ...state,
        response: {
          ...state.response,
          items: [...action.payload],
        },
      };
    },
    saveLoading(state, actions: any) {
      return {
        ...state,
        btnLoading: actions.payload,
      };
    },
  },
};

export const curdModel = createModel(curd);
