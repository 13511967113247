import { HomeOutlined, ClockCircleOutlined, AppstoreOutlined, ShopOutlined } from '@ant-design/icons';

export interface IRouter {
  name: string;
  key?: string;
  icon: JSX.Element;
  type?: string;
  children?: IRouter[];
}

const router = [
  {
    name: '首页',
    key: '/',
    icon: <HomeOutlined />,
  },
  {
    key: 'time_slot',
    name: '时间段管理',
    icon: <ClockCircleOutlined />,
  },
  {
    key: 'meal_system',
    name: '菜系管理',
    icon: <AppstoreOutlined />,
  },
  {
    name: '菜品管理',
    key: 'dish',
    type: 'group',
    icon: <ShopOutlined />,
    children: [
      {
        name: '菜品管理',
        key: 'manager',
        icon: <ShopOutlined />,
      },
      {
        name: '规格组管理',
        key: 'spec_group',
        icon: <ShopOutlined />,
      },
      {
        key: 'spec_item',
        name: '规格项',
        icon: <ShopOutlined />,
      },
    ],
  },
];

export default router;
