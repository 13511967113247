import { put, call, select } from 'redux-saga/effects';

// src/model.js
export function createModel({ namespace, state, reducers, effects }) {
  const prefixedReducers = {};
  const prefixedEffects = {};

  // 绑定 namespace 到 reducers 的 action type
  Object.keys(reducers).forEach((type) => {
    prefixedReducers[`${namespace}/${type}`] = reducers[type];
  });

  // 绑定 namespace 到 effects 的 action type
  Object.keys(effects).forEach((type) => {
    prefixedEffects[`${namespace}/${type}`] = function* (action) {
      // 在 effect 中触发 reducer，可以简化调用
      yield effects[type](action, {
        put: (effect) => {
          // 取出 effect 中的 type 字段，剩余的字段作为参数传递 ,避免后边字段把type覆盖
          const { type, ...args } = effect;
          const splitType = type.split('/');
          if (splitType.length > 1) {
            return put({ type, ...args });
          }

          return put({ type: `${namespace}/${effect.type}`, ...args });
        },
        call,
        select,
      });
    };
  });

  return {
    namespace,
    state,
    reducers: prefixedReducers,
    effects: prefixedEffects,
  };
}
