// src/rootSaga.js
import { all, takeEvery } from 'redux-saga/effects';

export function createRootSaga(models) {
  function* watchAll() {
    for (const model of models) {
      if (model.effects) {
        for (const effect in model.effects) {
          yield takeEvery(effect, model.effects[effect]);
        }
      }
    }
  }

  return function* rootSaga() {
    yield all([watchAll()]);
  };
}
