import { createTimeSlot, deleteTimeSlot, getDetail, getList } from '@/api/time_slot';
import { createModel } from './handler/handleModel';
import { notification } from 'antd';
import { NotificationIcon } from '@/utils';
import dayjs from 'dayjs';

export const time_slot = createModel({
  namespace: 'time_slot',
  state: {
    visible: false,
    loading: false,
    detail: null,
    list: [],
  },
  effects: {
    *getList({ payload }, { call, put, select }) {
      const list = yield select((state) => state.time_slot.list);
      if (list.length > 0) return;
      // 获取时间段列表
      const res = yield call(getList, payload);
      if (res && res.list) {
        yield put({
          type: 'saveList',
          payload: res.list,
        });
      }
    },
    *addTimeSlot({ payload }, { call, put }) {
      const { timeSlot, form } = payload;
      const res = yield call(createTimeSlot, timeSlot);
      if (res) {
        // 返回失败报错
        notification.open({
          message: '成功',
          description: '创建成功',
          icon: NotificationIcon('success'),
        });
        yield put({
          type: 'timeSlotVisible',
          payload: { visible: false },
        });
        yield put({
          type: 'curd/use_query',
          services: 'time_slot.getList',
          form,
        });
      }
    },
    *editTimeSlot({ payload }, { call, put }) {
      // 查询单挑数据
      const res = yield call(getDetail, payload.id);
      if (res) {
        yield put({
          type: 'saveRows',
          payload: {
            ...res,
            start_time: dayjs(res.start_time),
            end_time: dayjs(res.end_time),
          },
        });
        yield put({
          type: 'timeSlotVisible',
          payload: { visible: true },
        });
      }
    },
    *deleteTimeSlot({ payload }, { call, put }) {
      const { id, form } = payload;
      // 删除数据
      const res = yield call(deleteTimeSlot, id);
      if (res) {
        notification.open({
          message: '成功',
          description: '删除成功',
          icon: NotificationIcon('success'),
        });
        yield put({
          type: 'curd/use_query',
          services: 'time_slot.getList',
          form,
        });
      }
    },
  },
  reducers: {
    timeSlotVisible(state, { payload }) {
      return {
        ...state,
        visible: payload.visible,
        saveRows: null,
      };
    },
    saveRows(state, { payload }) {
      return {
        ...state,
        detail: payload,
      };
    },
    saveList(state, { payload }) {
      return {
        ...state,
        list: payload,
      };
    },
  },
});
