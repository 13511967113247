import React, { useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
interface Props {
  callback: (values: any) => void;
}
const { Option } = Select;

const CreateDishModal: React.FC<Props> = (props) => {
  const { callback } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { loading, detail } = useSelector((state: any) => state.dish);
  const { list: timeSlotList } = useSelector((state: any) => state.time_slot);
  const { list: mealSystemList } = useSelector((state: any) => state.meal_system);

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        ...detail,
      });
    }
    return () => {
      form.resetFields();
      dispatch({
        type: 'dish/saveRows',
        payload: null,
      });
    };
  }, [detail]);

  const handleOk = async () => {
    form.submit();
  };

  const onCancel = () => {
    dispatch({
      type: 'dish/DishVisible',
      payload: { visible: false },
    });
  };

  const onFinish = (values) => {
    callback({ ...detail, ...values });
  };

  return (
    <Modal
      title="新增时间段"
      // open={visible}
      open
      onOk={handleOk}
      onCancel={onCancel}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="菜品名称" name="name" rules={[{ required: true, message: '请输入时间段名称' }]}>
          <Input placeholder="请输入菜品名称" />
        </Form.Item>
        <Form.Item label="时间段" name="time_slot_ids" rules={[{ required: true, message: '请选择时间段' }]}>
          <Select mode="multiple" placeholder="请选择时间段">
            {timeSlotList?.map((item: any) => {
              return (
                <Option key={item.record_id} value={item.record_id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="所属菜系" name="meal_system_id" rules={[{ required: true, message: '请选择所属菜系' }]}>
          <Select placeholder="请选择所属菜系">
            {mealSystemList?.map((item: any) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateDishModal;
