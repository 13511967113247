import Curd from '../components/curd';
import { Button, Form, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import CreateDishModal from './CreateDishModal';

export default function Dish() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { visible } = useSelector((state: any) => state.dish);

  const column = [
    {
      title: '菜品名称',
      dataIndex: 'name',
      width: 80,
    },
    {
      title: '所属时间段',
      dataIndex: 'time_slot',
      width: 100,
      render: (val: any) => {
        return (val && val.length > 0 && val.join(',')) || '';
      },
    },
    {
      title: '所属菜系',
      dataIndex: 'meal_system',
      width: 100,
    },
    {
      title: '描述',
      dataIndex: 'description',
      width: 100,
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      width: 100,
      render: (val) => val && dayjs(val).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 100,
      render: (text, record) => (
        <>
          <a
            onClick={() => {
              handleEditDish(record);
            }}
          >
            编辑
          </a>
          <a
            className="ml5"
            onClick={() => {
              dispatch({
                type: 'dish/deleteDish',
                payload: {
                  id: record.record_id,
                  form,
                },
              });
            }}
          >
            删除
          </a>
        </>
      ),
    },
  ];

  const handleEditDish = (record) => {
    dispatch({
      type: 'dish/editDish',
      payload: {
        id: record.record_id,
      },
    });
    // 获取时间段列表
    dispatch({
      type: 'time_slot/getList',
    });
    // 获取菜系列表
    dispatch({
      type: 'meal_system/getList',
      payload: {
        pageSize: 100,
        current: 1,
      },
    });
  };

  const handleAddDish = () => {
    // 获取时间段列表
    dispatch({
      type: 'time_slot/getList',
    });
    // 获取菜系列表
    dispatch({
      type: 'meal_system/getList',
      payload: {
        q: 'page',
        pageSize: 100,
        current: 1,
      },
    });
    dispatch({
      type: 'dish/DishVisible',
      payload: {
        visible: true,
      },
    });
  };

  const handleCreateDish = (values) => {
    const Dish = {
      ...values,
    };
    dispatch({
      type: 'dish/addDish',
      payload: { Dish, form },
    });
  };

  return (
    <>
      <Curd
        table={{
          columns: column,
          tableHeader: (
            <div className="tableTitle">
              <Button type="primary" onClick={handleAddDish}>
                新增菜品
              </Button>
            </div>
          ),
        }}
        search={{
          form,
          total: true,
          services: 'dish.getDishList',
        }}
      >
        <Form.Item name="name">
          <Input placeholder="请输入关键字" prefix={<SearchOutlined />} allowClear type="search" />
        </Form.Item>
      </Curd>
      {visible && <CreateDishModal callback={handleCreateDish} />}
    </>
  );
}
