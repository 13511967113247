import Curd from '../components/curd';
import { Button, Form, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import AddTimeSlotModal from './addTimeSlotModal';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

export default function time_slot() {
  const dispatch = useDispatch();
  const { visible } = useSelector((state: any) => state.time_slot);
  const [form] = Form.useForm();

  const column = [
    {
      title: '名称',
      dataIndex: 'name',
      width: 80,
    },
    {
      title: '开始时间',
      dataIndex: 'start_time',
      width: 100,
      render: (val) => val > 0 && dayjs(val).format('HH:mm'),
    },
    {
      title: '结束时间',
      dataIndex: 'end_time',
      width: 100,
      render: (val) => val > 0 && dayjs(val).format('HH:mm'),
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      width: 100,
      render: (val) => val && dayjs(val).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 100,
      render: (text, record) => (
        <>
          <a
            onClick={() => {
              dispatch({
                type: 'time_slot/editTimeSlot',
                payload: { id: record.record_id },
              });
            }}
          >
            编辑
          </a>
          <a
            className="ml5"
            onClick={() => {
              dispatch({
                type: 'time_slot/deleteTimeSlot',
                payload: {
                  id: record.record_id,
                  form,
                },
              });
            }}
          >
            删除
          </a>
        </>
      ),
    },
  ];

  const handleTimeSlotVisible = () => {
    dispatch({
      type: 'time_slot/timeSlotVisible',
      payload: {
        visible: true,
      },
    });
  };

  const handleCreateTimeSlot = (values) => {
    const timeSlot = {
      ...values,
      start_time: dayjs(values.start_time).valueOf(),
      end_time: dayjs(values.end_time).valueOf(),
    };
    dispatch({
      type: 'time_slot/addTimeSlot',
      payload: { timeSlot, form },
    });
  };

  return (
    <>
      <Curd
        table={{
          columns: column,
          tableHeader: (
            <div className="tableTitle">
              <Button type="primary" onClick={handleTimeSlotVisible}>
                新增时间段
              </Button>
            </div>
          ),
        }}
        search={{
          form,
          total: true,
          services: 'time_slot.getList',
        }}
      >
        <Form.Item name="name">
          <Input placeholder="请输入关键字" prefix={<SearchOutlined />} allowClear type="search" />
        </Form.Item>
      </Curd>
      {visible && <AddTimeSlotModal callback={handleCreateTimeSlot} />}
    </>
  );
}
