import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';

const request = axios.create({
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

request.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  return config;
});

request.interceptors.response.use((config: AxiosResponse) => {
  return config.data;
});

export default request;
