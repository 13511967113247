import { Form, FormInstance, FormProps, Row, Col, Button } from 'antd';
import React, { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import style from './index.less';
import { useSelector } from 'react-redux';

type handlequery = () => void;
type handlereset = () => void;
export interface searchProps extends FormProps {
  callback?: (form: FormInstance, value: any) => void;
  more?: boolean;
  total?: boolean;
  form: FormInstance;
  valueChange?: (changeValue: any, allValue: any) => void;
  query?: handlequery | boolean;
  reset?: handlereset | boolean;
  text?: React.ReactNode;
  children?: React.ReactNode;
}

const search: React.FC<searchProps> = (props) => {
  const { children, callback, total, more, form, valueChange, query, reset, text } = props;

  const [able, SetAble] = useState<boolean>(true);

  const { response } = useSelector((state: any) => state.curd);
  const content = () => {
    const count = React.Children.count(children);
    return (
      <Row>
        {React.Children.map(children, (item, index) => {
          if (index) {
            return <Col span={4}>{item}</Col>;
          }
          if (index === count - 1) {
            return (
              <>
                <Col span={6}>{item}</Col>
              </>
            );
          }
          return <Col span={4}>{item}</Col>;
        })}
        <Col span={6}>
          {query && (
            <Button
              className={style.search_btn}
              style={{
                color: '#1D78FA',
              }}
              type="link"
              htmlType="submit"
            >
              搜索
            </Button>
          )}
          {reset && (
            <Button
              className={style.search_btn}
              style={{
                color: '#1D78FA',
              }}
              onClick={() => {
                form.resetFields();
              }}
              type="link"
            >
              重置
            </Button>
          )}
        </Col>
      </Row>
    );
  };
  const handlemorecontent = () => {
    const data = React.Children.toArray(children);
    return (
      <>
        <Row>
          {React.Children.map(
            data.filter((item: any, index: number) => index === 0 && item),
            (item) => {
              return (
                <>
                  <Col span={7}>{item}</Col>
                  <Button
                    className={style.btn}
                    onClick={() => {
                      SetAble(!able);
                    }}
                  >
                    {(able && '更多筛选') || '收起筛选'}
                    {(!able && <UpOutlined />) || <DownOutlined />}
                  </Button>
                  {(total && (
                    <span className={style.total}>
                      共{response.total}条{text && <span>,{text}</span>}
                    </span>
                  )) ||
                    null}
                </>
              );
            },
          )}
        </Row>
        {!able && (
          <Row>
            {React.Children.map(
              data.filter((item: any, index: number) => index !== 0 && item),
              (item) => {
                return <Col span={7}>{item}</Col>;
              },
            )}
          </Row>
        )}
        <div>
          {query && (
            <Button
              className={style.search_btn}
              style={{
                color: '#1D78FA',
              }}
              htmlType="submit"
            >
              搜索
            </Button>
          )}
          {reset && (
            <Button
              className={style.search_btn}
              style={{
                color: '#1D78FA',
              }}
              onClick={() => {
                form.resetFields();
              }}
            >
              重置
            </Button>
          )}
        </div>
      </>
    );
  };
  return (
    <Form
      form={form}
      onFinish={(value) => {
        callback && callback(form, value);
      }}
      onValuesChange={(changeValue, allValue) => {
        valueChange && valueChange(changeValue, allValue);
      }}
      colon={false}
      className={style.form}
    >
      {(more && handlemorecontent()) || content()}
    </Form>
  );
};

export default search;
