// src/models/user.js
import { NotificationIcon } from '@/utils';
import { createModel } from './handler/handleModel';
import { createDish, deleteDish, getDishDetail, updateDish } from '@/api/dish';
import { notification } from 'antd';

export const dishModel = createModel({
  namespace: 'dish',
  state: {
    visible: false,
    detail: null,
  },
  reducers: {
    DishVisible(state, { payload }) {
      return {
        ...state,
        visible: payload.visible,
      };
    },
    saveRows(state, { payload }) {
      return {
        ...state,
        detail: payload,
      };
    },
  },
  effects: {
    *addDish({ payload }, { call, put }) {
      const { Dish, form } = payload;
      let res = null;
      if (Dish.record_id) {
        res = yield call(updateDish, Dish);
      } else {
        res = yield call(createDish, Dish);
      }
      if (res) {
        notification.open({
          message: '成功',
          description: Dish.record_id ? '更新成功' : '创建成功',
          icon: NotificationIcon('success'),
        });
        yield put({
          type: 'DishVisible',
          payload: { visible: false },
        });
        yield put({
          type: 'curd/use_query',
          services: 'dish.getDishList',
          form,
        });
      }
    },
    *editDish({ payload }, { call, put }) {
      const res = yield call(getDishDetail, payload.id);
      if (res) {
        yield put({
          type: 'saveRows',
          payload: res,
        });
        yield put({
          type: 'DishVisible',
          payload: { visible: true },
        });
      }
    },
    *deleteDish({ payload }, { call, put }) {
      const res = yield call(deleteDish, payload.id);
      if (res) {
        notification.open({
          message: '成功',
          description: '删除成功',
          icon: NotificationIcon('success'),
        });
        yield put({
          type: 'curd/use_query',
          services: 'dish.getDishList',
          form: payload.form,
        });
      }
    },
  },
});
