import { Form, Input, Modal } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function CreateMealSystemModel({ callback }) {
  const [form] = Form.useForm();
  const { loading } = useSelector((state: any) => state.meal_system);
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);
  const handleOk = async () => {
    form.submit();
  };

  const onCancel = () => {
    dispatch({
      type: 'meal_system/mealSystemVisible',
      payload: { visible: false },
    });
  };

  const onFinish = (values) => {
    callback({ ...values });
  };

  return (
    <Modal open title="新增菜系" onOk={handleOk} onCancel={onCancel} confirmLoading={loading}>
      <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onFinish={onFinish}>
        <Form.Item label="名称" name="name" rules={[{ required: true, message: '请输入名称!' }]}>
          <Input placeholder="请输入名称" />
        </Form.Item>
        <Form.Item label="描述" name="description">
          <Input placeholder="请输入描述" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
