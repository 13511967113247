import request from '@/api/request';

export const getWeather = async (params) => {
  const res = await request('/website/weather', {
    method: 'GET',
    params,
  });

  return res;
};
export const getConstellation = async (params) => {
  const res = await request('/website/constellation', {
    method: 'GET',
    params,
  });

  return res;
};
