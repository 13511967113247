// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.7.1_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header__bzlcNlwKtH3U {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.header_left__PF9SHeQ2e9Fu {\n  display: flex;\n}\n.header_left_title__xiKR68XyaQja {\n  margin-left: 0.625rem;\n  font-size: 1.125rem;\n  font-weight: bold;\n  color: #1890ff;\n}\n.header_right__ytFcqu5piouR {\n  margin-right: 1.25rem;\n  font-size: 0.875rem;\n}\n", "",{"version":3,"sources":["webpack://./src/layout/layout.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AAAE;EACE,aAAA;AAEJ;AAAI;EACE,qBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;AAEN;AAEE;EACE,qBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  &_left {\n    display: flex;\n\n    &_title {\n      margin-left: 0.625rem;\n      font-size: 1.125rem;\n      font-weight: bold;\n      color: #1890ff;\n    }\n  }\n\n  &_right {\n    margin-right: 1.25rem;\n    font-size: 0.875rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header__bzlcNlwKtH3U",
	"header_left": "header_left__PF9SHeQ2e9Fu",
	"header_left_title": "header_left_title__xiKR68XyaQja",
	"header_right": "header_right__ytFcqu5piouR"
};
export default ___CSS_LOADER_EXPORT___;
