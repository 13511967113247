import { useRef, useEffect, useCallback } from 'react';
import success from '@/public/success_icon.png';
import error from '@/public/error_icon.png';
import info from '@/public/default_icon.png';
import warning from '@/public/warning_icon.png';

const data = { success, error, info, warning };

export const NotificationIcon = (type: keyof typeof data) => <img style={{ width: '32px', height: '32px' }} src={data[type]} />;

export function useDebounce(fn: any, delay: number) {
  const { current } = useRef({ fn, timer: null as unknown as NodeJS.Timeout });
  useEffect(
    function () {
      current.fn = fn;
    },
    [current.fn, fn],
  );

  return useCallback(
    function (...args: any[]) {
      if (current.timer) {
        clearTimeout(current.timer as unknown as number);
      }
      current.timer = setTimeout(() => {
        current.fn.call(current.fn, ...args);
      }, delay);
    },
    [current.fn, current.timer, delay],
  );
}

export function mobileHide(e: string) {
  const value = e;
  if (value) {
    return value.replace(/(\w{3})\w*(\w{4})/, '$1***$2');
  }
  return value;
}

export function idCardHide(e: string) {
  const value = e;
  if (value) {
    return value.replace(/(\w{3})\w*(\w{4})/, '$1**********$2');
  }
}

export function bankCardHide(e: string) {
  const value = e;
  if (value) {
    return value.replace(/(\w{0})\w*(\w{4})/, '$1**** **** **** $2');
  }
}

export function numberToWeekday(num: number) {
  const weekdays = [
    '', // 占位，没有“星期零”
    '星期一', // 1
    '星期二', // 2
    '星期三', // 3
    '星期四', // 4
    '星期五', // 5
    '星期六', // 6
    '星期日', // 7
  ];

  if (num >= 1 && num <= 7) {
    return weekdays[num];
  } else {
    return '请输入有效的数字（1-7）';
  }
}

// 天气晴朗图标转换
export function weatherIcon(dayweather: string, nightweather: string) {
  const weatherIcons = {
    晴: '☀️',
    多云: '⛅️',
    阴: '☁️',
    阵雨: '🌦',
    雷阵雨: '⛈',
    小雨: '🌧',
    中雨: '🌧',
    大雨: '🌧',
    暴雨: '🌧',
    雨夹雪: '🌨',
    小雪: '🌨',
    中雪: '🌨',
    大雪: '🌨',
    暴雪: '🌨',
    雾: '🌫',
    冰雹: '🌩',
    雨: '🌧',
    雪: '🌨',
    霾: '🌫',
  };
  if (dayweather == '') return '❓';
  if (dayweather === nightweather) {
    return weatherIcons[dayweather];
  } else {
    return weatherIcons[dayweather] + ' ~ ' + weatherIcons[nightweather];
  }
}

// 天气文字处理
export function weatherText(dayweather: string, nightweather: string) {
  if (dayweather === nightweather) {
    return dayweather;
  } else {
    return dayweather + '转' + nightweather;
  }
}
