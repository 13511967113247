import Table from '@/pages/components/curd/table';
import Search from '@/pages/components/curd/search';
import { FormInstance } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { searchProps } from './search';
import { tableProps } from './table';
import React, { useEffect } from 'react';
import { useDebounce } from '@/utils';
import { useLocation } from 'react-router-dom';

interface search extends searchProps {
  handleValueChange?: (changeValue: any, allValue: any, props: Props) => void;
  handlelistdistpatch?: (form: FormInstance, value: any, props: Props) => void;
  services: string;
}
interface Props {
  search: search;
  table: tableProps;
  children?: React.ReactNode;
}

const curd: React.FC<Props> = (props) => {
  const { table, search, children } = props;
  const location = useLocation();
  const dispatch = useDispatch();

  const { state: query } = location;

  const { form, services, handleValueChange, handlelistdistpatch, ...rest } = search;

  const {
    response: { total },
  } = useSelector((state: any) => state.curd);

  useEffect(() => {
    dispatch({
      type: 'curd/use_query',
      form,
      services,
      query,
      value: form.getFieldsValue(),
    });
    return () => {
      dispatch({
        type: 'curd/save_pagination',
        pagination: {
          pageSize: 10,
          current: 1,
        },
      });
    };
  }, [search.services]);

  return (
    <>
      <Search
        {...rest}
        form={form}
        valueChange={useDebounce((changeValue: { keysearch: string }, allValue: any) => {
          if (changeValue.keysearch === '') {
            dispatch({
              type: 'curd/use_query',
              form,
              allValue,
              services,
              query,
            });
          }
          if (changeValue.keysearch) {
            return;
          }
          if (handleValueChange) {
            handleValueChange(changeValue, allValue, props);
            return;
          }
          dispatch({
            type: 'curd/use_query',
            form,
            value: allValue,
            services,
            query,
          });
        }, 1000)}
        callback={(form: FormInstance, value: any) => {
          if (handlelistdistpatch) {
            () => handlelistdistpatch(form, value, props);
            return;
          }
          dispatch({
            type: 'curd/use_query',
            form,
            value,
            services,
            query,
          });
        }}
      >
        {children}
      </Search>

      {/* {total > 0 && ( */}
      <Table
        onChange={(pagination) => {
          dispatch({
            type: 'curd/handle_pagination',
            pagination,
            form,
            value: form.getFieldsValue(),
            services,
            query,
          });
        }}
        {...table}
      />
      {/* )} */}
    </>
  );
};

export default curd;
