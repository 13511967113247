import { CalendarIcon, CloudIcon, StarIcon, CakeIcon, ShoppingBagIcon } from 'lucide-react';
import { Button } from '@/pages/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/pages/components/ui/card';

import { Badge } from '@/pages/components/ui/badge';
import { numberToWeekday, weatherIcon, weatherText } from '@/utils';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ZodiacSelector from './zodiacSelect';

export default function Home() {
  const { today, weather, constellation, birthDay, remindDay } = useSelector((state: any) => state.global);
  const { forecasts } = weather;
  
  const dispatch = useDispatch();

  useEffect(() => {
    // 初始化日期
    dispatch({
      type: 'global/init',
    });
  }, []);

  const handleOpenZodiacDialog = () => {
    dispatch({
      type: 'global/setZodiacDialogVisible',
      payload: {
        zodiacSelectDialogVisible: true,
      },
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-pink-50 to-purple-50 p-8">
      <div className="max-w-3xl mx-auto space-y-8">
        {/* Date Module */}
        <Card className="overflow-hidden">
          <CardHeader className="bg-pink-100">
            <CardTitle className="flex items-center text-pink-800">
              <CalendarIcon className="w-5 h-5 mr-2" />
              日期
            </CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-3xl font-bold text-gray-800">
                  {today?.cYear}年{today?.cMonth}月{today?.cDay}日
                </h2>
                <p className="text-sm text-gray-600">
                  农历
                  {today?.yearCn}
                  {today?.monthCn}
                  {today?.dayCn}
                </p>
              </div>
              <Button variant="outline" className="text-pink-600 border-pink-300 hover:bg-pink-50">
                查看日历
              </Button>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="bg-blue-100">
            <CardTitle className="flex items-center text-blue-800">
              <CloudIcon className="w-5 h-5 mr-2" />
              天气预报(盐城)
            </CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div className="flex justify-between">
              {forecasts?.map((item) => {
                return item?.casts?.map((WeatherItem) => {
                  return (
                    <div className="text-center" key={WeatherItem.week}>
                      <p className="font-semibold">{numberToWeekday(Number(WeatherItem.week))}</p>
                     <p> {weatherIcon(WeatherItem.dayweather, WeatherItem.nightweather)}</p>
                     <p>{weatherText(WeatherItem.dayweather,WeatherItem.nightweather)}</p>
                      {/* <CloudIcon className="w-10 h-10 mx-auto my-2 text-blue-500" /> */}
                      <p className="text-sm">
                        {WeatherItem.daytemp}°C / {WeatherItem.nighttemp}°C
                      </p>
                    </div>
                  );
                });
              })}
            </div>
          </CardContent>
        </Card>

        {/* Zodiac Module */}
        <Card>
          <CardHeader className="bg-yellow-100">
            <CardTitle className="flex items-center text-yellow-800">
              <StarIcon className="w-5 h-5 mr-2" />
              星座运势
            </CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div>
              <h3 className="font-semibold text-lg">{constellation.name}</h3>
              <p className="text-gray-700">{constellation.summary}</p>
              <div className="mt-4 flex items-center justify-between">
                <div>
                  <Badge variant="outline" className="mr-2">
                    幸运色：{constellation.color}
                  </Badge>
                  <Badge variant="outline">幸运数字：{constellation.number}</Badge>
                </div>
                <Button variant="outline" className="text-yellow-600 border-yellow-300 hover:bg-yellow-50" onClick={handleOpenZodiacDialog}>
                  更换星座
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Meal Info Module */}
        <Card>
          <CardHeader className="bg-green-100">
            <CardTitle className="flex items-center text-green-800">
              <ShoppingBagIcon className="w-5 h-5 mr-2" />
              昨日用餐情况
            </CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-semibold">午餐：沙拉套餐</h3>
                <p className="text-sm text-gray-600">总卡路里：450 大卡</p>
              </div>
              <div className="text-right">
                <p className="text-sm font-medium text-gray-700">评分</p>
                <div className="flex items-center">
                  <StarIcon className="w-4 h-4 text-yellow-400 fill-current" />
                  <StarIcon className="w-4 h-4 text-yellow-400 fill-current" />
                  <StarIcon className="w-4 h-4 text-yellow-400 fill-current" />
                  <StarIcon className="w-4 h-4 text-yellow-400 fill-current" />
                  <StarIcon className="w-4 h-4 text-gray-300 fill-current" />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Birthday Countdown Module */}
        <Card>
          <CardHeader className="bg-red-100">
            <CardTitle className="flex items-center text-red-800">
              <CakeIcon className="w-5 h-5 mr-2" />
              生日倒计时
            </CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div className="text-center">
              <h3 className="text-2xl font-bold text-red-600">还有 {remindDay} 天</h3>
              <p className="text-sm text-gray-600 mt-2">
                {birthDay?.cYear}年{birthDay?.cMonth}月{birthDay?.cDay}日
              </p>
              <div className="mt-4">
                <Button className="bg-red-500 hover:bg-red-600 text-white">设置提醒</Button>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Quick Order Module */}
        {/* <Card>
          <CardContent className="p-6">
            <Button className="w-full bg-gradient-to-r from-pink-500 to-purple-500 text-white text-lg py-6 rounded-full shadow-lg hover:shadow-xl transition duration-300">快速点餐</Button>
          </CardContent>
        </Card> */}
      </div>
      <ZodiacSelector />
    </div>
  );
}
