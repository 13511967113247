// src/models/user.js
// import { call, put, select } from 'redux-saga/effects';
import { getConstellation, getWeather } from '@/api/global';
import { createModel } from './handler/handleModel';
import dayjs from 'dayjs';
import solarLunar from 'solarlunar';

export const zodiacSigns = [
  {
    name: '白羊座',
    date: '3月21日 - 4月19日',
    element: '火象星座',
    traits: '热情、勇敢、冲动',
  },
  {
    name: '金牛座',
    date: '4月20日 - 5月20日',
    element: '土象星座',
    traits: '稳重、固执、感性',
  },
  {
    name: '双子座',
    date: '5月21日 - 6月20日',
    element: '风象星座',
    traits: '机智、善变、好奇',
  },
  {
    name: '巨蟹座',
    date: '6月21日 - 7月22日',
    element: '水象星座',
    traits: '敏感、情绪化、有同情心',
  },
  {
    name: '狮子座',
    date: '7月23日 - 8月22日',
    element: '火象星座',
    traits: '自信、慷慨、固执',
  },
  {
    name: '处女座',
    date: '8月23日 - 9月22日',
    element: '土象星座',
    traits: '完美主义、挑剔、实际',
  },
  {
    name: '天秤座',
    date: '9月23日 - 10月22日',
    element: '风象星座',
    traits: '和谐、优雅、优柔寡断',
  },
  {
    name: '天蝎座',
    date: '10月23日 - 11月21日',
    element: '水象星座',
    traits: '神秘、热情、固执',
  },
  {
    name: '射手座',
    date: '11月22日 - 12月21日',
    element: '火象星座',
    traits: '乐观、诚实、冒险',
  },
  {
    name: '摩羯座',
    date: '12月22日 - 1月19日',
    element: '土象星座',
    traits: '野心、负责、保守',
  },
  {
    name: '水瓶座',
    date: '1月20日 - 2月18日',
    element: '风象星座',
    traits: '独立、原创、人道主义',
  },
  {
    name: '双鱼座',
    date: '2月19日 - 3月20日',
    element: '水象星座',
    traits: '富有同情心、直觉、理想主义',
  },
];
const initialState = {
  today: {},
  birthDay: {},
  remindDay: 0,
  globalSelectKey: [],
  weather: {},
  constellation: {},
  zodiacSelectDialogVisible: false,
  selectedZodiac: zodiacSigns[4],
};

export const globalModel = createModel({
  namespace: 'global',
  state: initialState,
  reducers: {
    setToday(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    setGlobalSelectKey(state, { payload }) {
      return {
        ...state,
        globalSelectKey: payload,
      };
    },
    setWeather(state, { payload }) {
      return {
        ...state,
        weather: payload,
      };
    },
    setConstellation(state, { payload }) {
      return {
        ...state,
        constellation: payload,
      };
    },
    setZodiacDialogVisible(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    ZodiacSave(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *init(_, { put, select, call }) {
      // 获取今天的日期农历阴历
      const year = dayjs().year();
      const month = dayjs().month() + 1;
      const day = dayjs().date();

      const today = solarLunar.solar2lunar(year, month, day);
      const birthDay = solarLunar.lunar2solar(year + 1, 7, 14);
      const { cYear, cMonth, cDay } = birthDay;
      const todayNum = dayjs().valueOf();
      const birthDayNum = dayjs(`${cYear}-${cMonth}-${cDay}`).valueOf();
      const diff = birthDayNum - todayNum;
      // 根据diff 计算天数
      const days = Math.ceil(diff / (1000 * 60 * 60 * 24));

      yield put({
        type: 'setToday',
        payload: {
          today,
          birthDay,
          remindDay: days,
        },
      });
      const params = {
        city: '320900',
        extensions: 'all',
      };
      const weather = yield call(getWeather, params);
      if (weather.code === 200) {
        yield put({
          type: 'setWeather',
          payload: weather.data,
        });
      }
      yield put({
        type: 'queryConstellation',
      });
    },
    *queryConstellation({}, { put, call, select }) {
      const selectedZodiac = yield select((state) => state.global.selectedZodiac);
      const constellationParams = {
        consName: selectedZodiac.name,
        type: 'today',
      };

      const constellation = yield call(getConstellation, constellationParams);

      if (constellation.code === 200) {
        yield put({
          type: 'setConstellation',
          payload: constellation.data,
        });
      }
    },
  },
});
