import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Myrouter from '@/router';
import store from './store/handler/store';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import './global.less';
import zhCN from 'antd/locale/zh_CN';

dayjs.locale('zh-cn');

const root = createRoot(document.getElementById('root')!);

root.render(
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <Myrouter />
    </Provider>
  </ConfigProvider>,
);
