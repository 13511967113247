import { useState } from 'react';
import { Button } from '@/pages/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/pages/components/ui/dialog';
import { RadioGroup, RadioGroupItem } from '@/pages/components/ui/radio-group';
import { Label } from '@/pages/components/ui/label';
import { ScrollArea } from '@/pages/components/ui/scroll-area';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { zodiacSigns } from '@/store/global';

export default function ZodiacSelector() {
  const { zodiacSelectDialogVisible, selectedZodiac: defaultZodiac } = useSelector((state: any) => state.global);
  const [selectedZodiac, setSelectedZodiac] = useState(defaultZodiac); // 默认选择处女座

  const dispatch = useDispatch();

  const handleZodiacChange = (zodiac) => {
    setSelectedZodiac(zodiac);
  };

  const handleCancel = () => {
    dispatch({
      type: 'global/setZodiacDialogVisible',
      payload: { zodiacSelectDialogVisible: false },
    });
  };

  const handleOnok = () => {
    dispatch({
      type: 'global/ZodiacSave',
      payload: { zodiacSelectDialogVisible: false, selectedZodiac },
    });
    dispatch({
      type: 'global/queryConstellation',
    });
  };

  return (
    <Dialog
      open={zodiacSelectDialogVisible}
      onOpenChange={() => {
        dispatch({
          type: 'global/setZodiacDialogVisible',
          payload: {
            zodiacSelectDialogVisible: false,
          },
        });
      }}
    >
      <DialogContent className="sm:max-w-[425px] bg-gradient-to-r from-yellow-50 to-orange-50">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold text-yellow-700 text-center">选择你的星座</DialogTitle>
        </DialogHeader>
        <ScrollArea className="mt-4 max-h-[60vh] pr-4">
          <RadioGroup value={selectedZodiac.name} onValueChange={handleZodiacChange} className="space-y-4">
            {zodiacSigns.map((zodiac) => (
              <div
                key={zodiac.name}
                className="flex items-start space-x-3 p-3 rounded-lg transition-colors hover:bg-yellow-100"
                onClick={() => {
                  handleZodiacChange(zodiac);
                }}
              >
                <RadioGroupItem value={zodiac.name} id={zodiac.name} className="mt-1 text-yellow-600" />
                <div>
                  <Label htmlFor={zodiac.name} className="text-lg font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    {zodiac.name}
                  </Label>
                  <p className="text-sm text-gray-500 mt-1">{zodiac.date}</p>
                  <p className="text-sm text-gray-600 mt-1">
                    {zodiac.element} · {zodiac.traits}
                  </p>
                </div>
              </div>
            ))}
          </RadioGroup>
        </ScrollArea>
        <div className="mt-6 flex justify-end space-x-2">
          <Button
            variant="outline"
            onClick={() => {
              handleCancel();
            }}
          >
            取消
          </Button>
          <Button
            onClick={() => {
              handleOnok();
            }}
            className="bg-yellow-500 hover:bg-yellow-600 text-white"
          >
            确认
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
