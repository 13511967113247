import Curd from '../components/curd';
import { Button, Form, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import CreateMealSystemModel from './CreateModel';
import { useSelector } from 'react-redux';

export default function meal_system() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { visible } = useSelector((state: any) => state.meal_system);

  const column = [
    {
      title: '菜系名称',
      dataIndex: 'name',
      width: 80,
    },
    {
      title: '描述',
      dataIndex: 'description',
      width: 100,
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      width: 100,
      render: (val) => val && dayjs(val).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 100,
      render: (text, record) => (
        <>
          <a
            onClick={() => {
              dispatch({
                type: 'meal_system/editMealSystem',
                payload: { id: record.record_id },
              });
            }}
          >
            编辑
          </a>
          <a
            className="ml5"
            onClick={() => {
              dispatch({
                type: 'meal_system/deleteMealSystem',
                payload: {
                  id: record.record_id,
                  form,
                },
              });
            }}
          >
            删除
          </a>
        </>
      ),
    },
  ];

  const handleAddMealSystem = () => {
    dispatch({
      type: 'meal_system/mealSystemVisible',
      payload: {
        visible: true,
      },
    });
  };

  const handleCreateMealSystem = (values) => {
    const mealSystem = {
      ...values,
    };
    dispatch({
      type: 'meal_system/addMealSystem',
      payload: { mealSystem, form },
    });
  };

  return (
    <>
      <Curd
        table={{
          columns: column,
          tableHeader: (
            <div className="tableTitle">
              <Button type="primary" onClick={handleAddMealSystem}>
                新增菜系
              </Button>
            </div>
          ),
        }}
        search={{
          form,
          total: true,
          services: 'meal_system.getList',
        }}
      >
        <Form.Item name="name">
          <Input placeholder="请输入关键字" prefix={<SearchOutlined />} allowClear type="search" />
        </Form.Item>
      </Curd>
      {visible && <CreateMealSystemModel callback={handleCreateMealSystem} />}
    </>
  );
}
