import { Layout } from 'antd';
import MyHeader from './header';
import Context from './context';
// import MyFooter from './footer';

export default function layout() {
  return (
    <Layout>
      <MyHeader />
      <Context />
      {/* <MyFooter /> */}
    </Layout>
  );
}
