import request from '@/api/request';

export const getDishList = async (params) => {
  return request('/api/web/v1/dish', {
    method: 'GET',
    params,
  });
};

export const createDish = (data) => {
  return request('/api/web/v1/dish', {
    method: 'POST',
    data: data,
  });
};

export const getDishDetail = async (id) => {
  return request(`/api/web/v1/dish/${id}`, {
    method: 'GET',
  });
};

export const updateDish = (data) => {
  return request(`/api/web/v1/dish/${data.record_id}`, {
    method: 'PUT',
    data: data,
  });
};

export const deleteDish = (id) => {
  return request(`/api/web/v1/dish/${id}`, {
    method: 'DELETE',
  });
};
