import * as global from './global';
import * as time_slot from './time_slot';
import * as meal_system from './meal_system';
import * as dish from './dish';
const apirequest: any = {
  global,
  time_slot,
  meal_system,
  dish,
};

export default apirequest;
