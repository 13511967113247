import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import style from './index.less';
import React, { useEffect } from 'react';
import { TableProps } from 'antd/lib/table/index';

export interface tableProps extends TableProps<Record<string, number>> {
  tableHeader?: React.ReactNode;
}

const table: React.FC<tableProps> = (props) => {
  const { columns, pagination = {}, scroll, tableHeader, ...args } = props;

  const { response } = useSelector((state: any) => state.curd);
  const [newScroll, setNewScroll] = React.useState<number>(0);
  const { pagination: resPagination, list } = response;

  const paginationProps = {
    showSizeChanger: true,
    total: resPagination.total,
    showTotal: () => <span>共{resPagination.total}条</span>,
    pageSize: resPagination.pageSize,
    current: resPagination.current,
    showQuickJumper: true,
    ...pagination,
  };

  useEffect(() => {
    let newWidth = 0;
    columns?.forEach((item: any) => {
      newWidth += item.width;
    });
    setNewScroll(newWidth);
  }, [columns]);

  return (
    <div className={style.table_component}>
      {tableHeader}
      <Table
        className={style.table}
        columns={columns}
        dataSource={list}
        pagination={paginationProps}
        rowKey={(row) => row.record_id as unknown as string}
        scroll={(newScroll && { x: newScroll }) || scroll}
        {...args}
      />
    </div>
  );
};

export default table;
