import request from '@/api/request';

export const getList = async (params) => {
  return request('/api/web/v1/time_slot', {
    method: 'GET',
    params,
  });
};

export const createTimeSlot = (data) => {
  return request('/api/web/v1/time_slot', {
    method: 'POST',
    data: data,
  });
};

export const getDetail = async (id) => {
  return request(`/api/web/v1/time_slot/${id}`, {
    method: 'GET',
  });
};

export const updateTimeSlot = (data) => {
  return request(`/api/web/v1/time_slot/${data.id}`, {
    method: 'PUT',
    data: data,
  });
};

export const deleteTimeSlot = (id) => {
  return request(`/api/web/v1/time_slot/${id}`, {
    method: 'DELETE',
  });
};
