import { Layout, Image, Space, Avatar } from 'antd';
const { Header } = Layout;
import dawang from '@/public/dawang.png';
import s from './layout.less';
import { Link } from 'react-router-dom';

export default function MyHeader() {
  return (
    <Header className={s.header}>
      <div className={s.header_left}>
        <Image width="3.2rem" alt="大王" src={dawang} />
        <span className={s.header_left_title}>
          <Link to="/">大王点菜管理系统</Link>
        </span>
      </div>
      <div className={s.header_right}>
        <Space>
          <Avatar size={34} />
          <div>羊羊</div>
        </Space>
      </div>
    </Header>
  );
}
