import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from '@/layout/layout';
import Home from '@/pages/home/home';
import TimeSlot from '@/pages/time_slot';
import MealSystem from '@/pages/meal_system';
import Dish from '@/pages/dish';

function Myrouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="time_slot" element={<TimeSlot />} />
          <Route path="meal_system" element={<MealSystem />} />
          <Route path="manager" element={<Dish />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Myrouter;
