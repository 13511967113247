// src/store.js
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createRootReducer } from '../handler/handleReducer';
import { createRootSaga } from '../handler/handleSaga';
import { models } from '../handler/modelMap';

// 创建 Saga middleware
const sagaMiddleware = createSagaMiddleware();

// 创建 root reducer 和 root saga
const rootReducer = createRootReducer(models);
const rootSaga = createRootSaga(models);

// 创建 store
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// 运行 saga
sagaMiddleware.run(rootSaga);

export default store;
