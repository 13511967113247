// 菜系api
import request from '@/api/request';

export const getList = async (params) => {
  return request('/api/web/v1/meal_system', {
    method: 'GET',
    params,
  });
};

export const createMealSystem = (data) => {
  return request('/api/web/v1/meal_system', {
    method: 'POST',
    data: data,
  });
};

export const getDetail = async (id) => {
  return request(`/api/web/v1/meal_system/${id}`, {
    method: 'GET',
  });
};

export const updateMealSystem = (data) => {
  return request(`/api/web/v1/meal_system/${data.id}`, {
    method: 'PUT',
    data: data,
  });
};

export const deleteMealSystem = (id) => {
  return request(`/api/web/v1/meal_system/${id}`, {
    method: 'DELETE',
  });
};
