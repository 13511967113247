// 新增时间段弹窗
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, TimePicker } from 'antd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
interface Props {
  callback: (values: any) => void;
}

const AddTimeSlotModal: React.FC<Props> = (props) => {
  const { callback } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { visible, loading, detail } = useSelector((state: any) => state.time_slot);

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        ...detail,
      });
    }
    return () => {
      form.resetFields();
      dispatch({
        type: 'time_slot/saveRows',
        payload: null,
      });
    };
  }, [detail]);

  const handleOk = async () => {
    form.submit();
  };

  const onCancel = () => {
    dispatch({
      type: 'time_slot/timeSlotVisible',
      payload: { visible: false },
    });
  };

  const onFinish = (values) => {
    callback({ ...detail, ...values });
  };

  return (
    <Modal title="新增时间段" open={visible} onOk={handleOk} onCancel={onCancel} confirmLoading={loading}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="时间段名称" name="name" rules={[{ required: true, message: '请输入时间段名称' }]}>
          <Input placeholder="请输入时间段名称" />
        </Form.Item>
        <Form.Item label="开始时间" name="start_time" rules={[{ required: true, message: '请选择开始时间' }]}>
          <TimePicker placeholder="请选择开始时间" format="HH:mm" />
        </Form.Item>
        <Form.Item label="结束时间" name="end_time" rules={[{ required: true, message: '请选择结束时间' }]}>
          <TimePicker placeholder="请选择结束时间" format="HH:mm" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTimeSlotModal;
