// src/rootReducer.js
import { combineReducers } from 'redux';

export function createRootReducer(models) {
  const reducers = models.reduce((acc, model) => {
    acc[model.namespace] = (state = model.state, action) => {
      const handler = model.reducers[action.type];
      return handler ? handler(state, action) : state;
    };
    return acc;
  }, {});

  return combineReducers(reducers);
}
